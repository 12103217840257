import { ref } from 'vue'
import { navigateTo, useNuxtApp, useI18n, useSupabaseClient } from '#imports'

export function useUser() {
    const { $bus } = useNuxtApp()
    const supabase = useSupabaseClient()
    const { locale } = useI18n()
    const submitted = ref(false)
    const timeout = 2000

    const signIn = async (email: string, password: string) => {
        try {
            const { error } = await supabase.auth.signInWithPassword({ email, password })
            if (!error) {
                submitted.value = true
                setTimeout(() => {
                    navigateTo(`/${locale.value}/app`)
                }, timeout)
            } else {
                $bus.$emit('notification', { type: 'error', message: error.message })
                console.log(error.message)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const signUp = async (email: string, password: string, role = 'USER') => {
        try {
            const { error } = await supabase.auth.signUp({ email, password, options: { data: { role } } })
            if (!error) {
                submitted.value = true
                setTimeout(() => {
                    navigateTo(`/${locale.value}/app`)
                }, timeout)
            } else {
                $bus.$emit('notification', { type: 'error', message: error.message })
                console.log(error.message)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const signOut = async () => {
        try {
            const { error } = await supabase.auth.signOut()

            if (error) {
                $bus.$emit('notification', { type: 'error', message: error })
                console.log(error)
            }
        } catch (error) {
            console.log(error)
        } finally {
            navigateTo('/')
        }
    }

    return {
        signUp,
        signIn,
        signOut,
        submitted,
    }
}
